<template>
  <div class="dictionary container-page" style="min-width:1200px;">
    <!-- <h3 class="content-title">字典管理</h3> -->
    <div class="tabs-area">
      <!-- <el-tabs type="card" v-model="activeName">
        <el-tab-pane name="first">

        </el-tab-pane>
      </el-tabs> -->
      <div class="mainContent">
        <div style="display:flex;">
          <div  style="border:1px solid #f5f5f5;flex:1;">
            <div class="grid-content bg-purple" style="margin-top:20px;">
              <div class="searchArea">
                <el-form
                  hide-required-asterisk
                  :inline="true"
                  class="demo-form-inline"
                  @submit.native.prevent
                >
                  <el-form-item label="字典名" style="width: 165px">
                    <el-input
                      size="medium"
                      style="width: 110px"
                      @keyup.enter.native="queryDictionList()"
                      v-model="filter.dictionaryName"
                      placeholder="全部"
                      clearable
                    >
                    </el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button
                      type="primary"
                      size="medium"
                      class="theme-btn"
                      icon="el-icon-search"
                      :loading="$store.state.requestLoading"
                      @click="queryDictionList"
                      >查询</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div class="dataArea">
                <el-table
                  size="medium"
                  class="table01"
                  :data="dictionaryData.data"
                  v-loading="$store.state.requestLoading"
                  :header-cell-style="{ color: '#333', fontWeight: 900 }"
                  style="width: 100%"
                >
                  <el-table-column prop="id" label="编号" width="55">
                    <template slot-scope="scope">
                      {{ scope.$index + 1 }}
                    </template>
                  </el-table-column>
                  <el-table-column label="字典名">
                    <template slot-scope="scope">
                      <div
                        @click="
                          showItem(
                            scope.row.dictTypeKey,
                            scope.row.dictTypeName
                          )
                        "
                      >
                        <span style="margin-right: 10px">{{
                          scope.row.dictTypeName
                        }}</span>
                        <i
                          class="el-icon-arrow-right"
                          style="float:right;position:relative;top:5px;"
                        ></i>
                      </div>
                    </template>
                    <!-- <i class="el-icon-arrow-right"></i> -->
                  </el-table-column>
                </el-table>
                <!-- <div
                  class="paginationArea pagination"
                  style="text-align:right;margin:0.5rem 0;"
                >
                  <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="dictionaryData.total"
                    :current-page="dictionaryData.pageNo"
                    :page-size="dictionaryData.pageSize"
                    @current-change="handleChange"
                  >
                  </el-pagination>
                </div> -->
              </div>
            </div>
          </div>
          <div style="width:25px;"> </div>
          <div style="border:1px solid #f5f5f5;flex:3;">
            <div class="grid-content bg-purple" style="margin-top:20px;">
              <div class="searchArea">
                <el-form
                  hide-required-asterisk
                  :inline="true"
                  class="demo-form-inline"
                >
                  <el-form-item label="字典项名" style="width: 240px">
                    <el-input
                      size="medium"
                      style="width: 150px"
                      v-model="filter.dictionaryItemName"
                      placeholder="全部"
                      clearable
                      @keyup.enter.native="handleCurrentChange(0)"
                    >
                    </el-input>
                  </el-form-item>
                  <el-form-item label="字典项值" style="width: 240px">
                    <el-input
                      size="medium"
                      style="width: 150px"
                      v-model="filter.dictionaryItemValue"
                      placeholder="全部"
                      clearable
                      @keyup.enter.native="handleCurrentChange(0)"
                    >
                    </el-input>
                  </el-form-item>

                  <el-form-item>
                    <el-button
                      type="primary"
                      size="medium"
                      class="theme-btn"
                      icon="el-icon-search"
                      :loading="$store.state.requestLoading"
                      @click="handleCurrentChange(0)"
                      >查询</el-button
                    >
                    <el-button
                      type="primary"
                      size="medium"
                      class="theme-btn"
                      icon="el-icon-circle-plus-outline"
                      :loading="$store.state.requestLoading"
                      @click="add()"
                      >添加</el-button
                    >
                    <el-button
                      type="danger"
                      size="medium"
                      class="theme-btn"
                      :loading="$store.state.requestLoading"
                      @click="deleteDic"
                      >批量删除</el-button
                    >
                  </el-form-item>
                </el-form>
              </div>
              <div class="dataArea">
                <el-table
                  size="medium"
                  class="table02"
                  v-loading="$store.state.requestLoading"
                  :data="detailData.data"
                  style="width: 100%"
                  :header-cell-style="{ color: '#333', fontWeight: 900 }"
                  @selection-change="handleSelectionChange"
                  :row-class-name="tableRowClassName"
                  @cell-click="tabClick"
                >
                  <el-table-column type="selection" width="55">
                  </el-table-column>
                  <el-table-column prop="dictCode" label="字典项名">
                  </el-table-column>
                  <el-table-column prop="dictValue" label="字典项值">
                  </el-table-column>
                  <el-table-column label="上级" width="150">
                    <template slot-scope="scope">
                      <span v-if="scope.row.parent">
                        {{
                          scope.row.parent.dictTypeName +
                            " -  " +
                            scope.row.parent.dictValue
                        }}
                      </span>
                      <span v-else>
                        ——
                      </span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="sort" label="序号" width="80">
                    <template slot-scope="scope">
                      <span
                        v-if="
                          scope.row.index === tabClickIndex &&
                            tabClickLabel === '序号'
                        "
                      >
                        <el-input
                          v-model="scope.row.sort"
                          type="number"
                          min="1"
                          maxlength="5"
                          size="mini"
                          @blur="inputBlur(scope.row)"
                        />
                      </span>
                      <span v-else>{{ scope.row.sort }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="创建时间"  width="100">
                    <template slot-scope="scope">
                      {{
                        scope.row.createTime
                          ? $util.dateFormat(scope.row.createTime)
                          : $util.dateFormat(scope.row.createTime)
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column label="更新时间" width="100">
                    <template slot-scope="scope">
                      {{
                        scope.row.updateTime
                          ? $util.dateFormat(scope.row.updateTime)
                          : $util.dateFormat(scope.row.createTime)
                      }}
                    </template>
                  </el-table-column>
                  <el-table-column label="更多" width="150">
                    <template slot-scope="scope">
                      <el-button size="mini" @click="add(scope.row)">编辑</el-button>
                      <el-button
                        size="mini"
                        type="danger"
                        @click="deleteOne(scope.row.dictId)"
                        >删除</el-button
                      >
                      <!-- <span
                        style="color:#409EFF;cursor: pointer;"
                        @click="add(scope.row)"
                        >编辑</span
                      > -->
                      <!-- <span
                        @click="deleteOne(scope.row.dictId)"
                        style="color:#F56C6C;cursor: pointer;margin-left:0.5rem;"
                        >删除</span
                      > -->
                    </template>
                  </el-table-column>
                </el-table>
                <!-- <div
                  class="paginationArea pagination"
                  style="text-align:right;margin:0.5rem 0;"
                >
                  <el-pagination
                    background
                    layout="total, prev, pager, next"
                    :total="detailData.total"
                    :current-page="detailData.pageNo"
                    :page-size="detailData.pageSize"
                    @current-change="handleCurrentChange"
                  >
                  </el-pagination>
                </div> -->
              </div>
            </div>
          </div>
          <div  style="width:100px;"> </div>
        </div>

      </div>
      <div class="mainContent">
        <div style="display:flex;">
          <div  style="min-height:20px;flex:1;"
          class="dataArea">
            <div
              class="paginationArea pagination"
              style="text-align:right;margin:0.5rem 0;"
            >
              <el-pagination
                background
                layout="total, prev, pager, next"
                :total="dictionaryData.total"
                :current-page="dictionaryData.pageNo"
                :page-size="dictionaryData.pageSize"
                @current-change="handleChange"
              >
              </el-pagination>
            </div>
          </div>

          <div  style="width:25px;"> </div>
          <div  class="dataArea" style="min-height:20px;flex:3;">
            <div
              class="paginationArea pagination"
              style="text-align:right;margin:0.5rem 0;"
            >
              <el-pagination
                background
                layout="total, prev, pager, next"
                :total="detailData.total"
                :current-page="detailData.pageNo"
                :page-size="detailData.pageSize"
                @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
          <div  style="width:100px;"> </div>
        </div>
      </div>
    </div>
    <!-- 添加字典 -->
    <div class="dialog">
      <!-- <el-dialog title="新增字典" v-if="dialog" custom-class="them-dialog">
        <addDctionary ></addDctionary>
      </el-dialog> -->
      <el-dialog
        :title="title"
        :close-on-click-modal="false"
        v-if="dialog"
        :visible.sync="dialog"
        width="40rem"
        @close='resetForm()'
      >
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="formModel"
          class="demo-dynamic"
          label-width="80px"
        >
          <el-form-item label="名称：" prop="dictCode">
            <el-input
              v-model.trim="ruleForm.dictCode"
              placeholder="请输入字典项名称"
              style="width:300px;"
              @input="change($event)"
            ></el-input>
          </el-form-item>
          <el-form-item label="   值：" prop="dictValue">
            <el-input
              v-model.trim="ruleForm.dictValue"
              placeholder="请输入字典项值"
              style="width:300px;"
              @input="change($event)"
            ></el-input>
          </el-form-item>
          <el-form-item label="上级：" prop="upKey">
            <el-select
              v-model="filters.value1"
              clearable
              placeholder="请选择字典"
              @change="selectList"
            >
              <el-option
                v-for="item in dictionaryData.data01"
                :key="item.dictTypeKey"
                :label="item.dictTypeName"
                :value="item.dictTypeKey"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="filters.value2"
              filterable
              placeholder="请选择字典项"
              @change="selectTwoList(filters.value2)"
            >
              <el-option
                v-for="item in select2"
                :key="item.dictId"
                :label="item.dictValue"
                :value="item.dictId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addDictionary('formModel')"
            >保存</el-button
          >
          <el-button
            @click="
              dialog = false;
              resetForm();
            "
            >取消</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
// import addDctionary from "./addDctionary.vue";
// import { API } from './constant'
// import { post } from 'common/js/request'
export default {
  name: 'dictionary',
  components: {
    // addDctionary: addDctionary
  },
  computed: {},
  data () {
    return {
      // loading: true,
      activeName: 'first',
      filter: {
        dictionaryName: ''
      },
      title: '',
      filters: {
        value1: '',
        value2: ''
      },
      dictionaryData: {
        data: [],
        data01: [],
        total: 0,
        pageNo: 0,
        PageSize: 0
      },
      detailData: {
        data: [],
        total: 0,
        pageNo: 0,
        PageSize: 0
      },
      dialog: false,
      flag: false,
      ruleForm: {
        dictCode: '',
        dictValue: ''
      },
      tabClickIndex: '',
      rules: {
        dictCode: [{ required: true, message: '请输入名称', trigger: 'blur' }],

        dictValue: [{ required: true, message: '请输入值', trigger: 'blur' }]
      },
      // 分页
      pager: {
        total: 0,
        pageNo: 1,
        pageSize: 10
      },
      multipleSelection: [],
      dictIds: [], // 储存删除字典的id
      select2: [],
      select2Loading: true,
      editFlag: false
    }
  },
  watch: {},
  created () {
    this.queryDictionList()
    this.selectDictionList()
  },
  methods: {
    handleSelectionChange (val) {
      this.multipleSelection = val
    },
    add (pramas) {
      this.dialog = true
      if (pramas) {
        this.title = '编辑字典项'
        this.detailDict(pramas.dictId)
        window.sessionStorage.setItem('_id', pramas.dictId)
        this.ruleForm.dictValue = pramas.dictValue
        this.ruleForm.dictCode = pramas.dictCode
        window.sessionStorage.setItem('_parentId', pramas.parentId)
        if (pramas.parentId && pramas.parent) {
          this.filters.value1 = pramas.parent.dictTypeName
          this.filters.value2 = pramas.parent.dictValue
        }

        this.editFlag = true
      } else {
        this.title = '添加字典项'
        this.editFlag = false
      }
    },
    // 左边的字典列表
    async queryDictionList (pageNumber) {
      if (isNaN(pageNumber)) {
        pageNumber = 0
      }
      const res = await this.$api.getLeftDictList(
        Object.assign(
          {
            pageNo: pageNumber || 0
          },
          { pageSize: 10 },
          { dictTypeName: this.filter.dictionaryName }
        )
      )
      if (res !== 'fail&&error') {
        const { data = [], pageNo, pageSize, total } = res
        this.dictionaryData.data = data
        this.dictionaryData.pageNo = pageNo
        this.dictionaryData.pageSize = pageSize
        this.dictionaryData.total = total
        // 右边默认显示左边第一项字典列表
        if (data && data.length > 0 && data[0].dictTypeKey) {
          this.dictionItemList(data[0].dictTypeKey)
          window.sessionStorage.setItem('dictType', data[0].dictTypeKey)
        }
        if (data && data.length > 0 && data[0].dictTypeName) {
          window.sessionStorage.setItem('dictTypeName', data[0].dictTypeName)
        }
      }
    },
    // 右边的字典项
    async queryDictionItemList (pageNumber) {
      const obj = {}
      obj.dictType = window.sessionStorage.getItem('dictType')
      obj.dictCode = this.filter.dictionaryItemName
      obj.dictValue = this.filter.dictionaryItemValue
      const res = await this.$api.getRightDictList(
        Object.assign(
          {
            pageNo: pageNumber || 0
          },
          { pageSize: 10 },
          obj
        )
      )
      if (res !== 'fail&&error') {
        const { data = [], pageNo, pageSize, total } = res
        this.detailData.data = data
        this.detailData.pageNo = pageNo
        this.detailData.pageSize = pageSize
        this.detailData.total = total
      }
    },
    // 添加字典
    addDictionary (formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.addDict()
        }
      })
    },
    async addDict () {
      const obj = {}
      obj.dictType = window.sessionStorage.getItem('dictType')
      obj.dictTypeName = window.sessionStorage.getItem('dictTypeName')
      if (this.filters.value2) {
        // 如果编辑状态
        if (this.editFlag) {
          obj.dictId = window.sessionStorage.getItem('_id')
          if (this.filters.value2 && window.sessionStorage.getItem('_parentId')) {
            obj.parentId = window.sessionStorage.getItem('_parentId')
          } else {
            obj.parentId = this.filters.value2
          }
        } else {
          obj.parentId = this.filters.value2
        }
        // obj.dictType=this.filters.value1;
      } else {
        if (this.editFlag) {
          obj.dictId = window.sessionStorage.getItem('_id')
        } else {
          obj.parentId = ''
        }
      }
      let res
      if (obj.dictId) {
        res = await this.$api.updateDict(Object.assign(obj, this.ruleForm))
      } else {
        res = await this.$api.saveDict(Object.assign(obj, this.ruleForm))
      }
      if (res !== 'fail&&error') {
        this.dialog = false
        this.filters.value2 = ''
        this.filters.value1 = ''
        this.ruleForm.dictCode = ''
        this.ruleForm.dictValue = ''
        this.resetForm()
        this.queryDictionItemList()
      }
    },
    async editRowDict (row) {
      const res = await this.$api.updateDict(Object.assign({}, row))
      if (res === false) {
        return
      }
      this.tabClickIndex = null
      this.tabClickLabel = ''
      this.queryDictionItemList()
    },
    // 失去焦点初始化
    inputBlur (row) {
      this.editRowDict(row)
    },
    tableRowClassName ({ row, rowIndex }) {
      // 把每一行的索引放进row
      row.index = rowIndex
    },
    tabClick (row, column, cell, event) {
      switch (column.label) {
        case '序号':
          this.tabClickIndex = row.index
          this.tabClickLabel = column.label
          break
        default:
          return
      }
      console.log('tabClick', this.tabClickIndex, row.sort)
    },
    async detailDict (id) {
      const res = await this.$api.getDictdetail({ dictId: id })
      //   const { data = [],...rest = {} } = res;
      // 出错不处理
      if (res === false) {
        return
      }
      if (res && res.parentId) {
        const resp = this.detailDict02(res.parentId)
      }
    },
    async detailDict02 (id) {
      const res = await this.$api.getDictdetail({ dictId: id })
      if (res === false) {
        return
      }
      window.sessionStorage.setItem('_parentId', id)
      this.filters.value1 = res.dictTypeName
      this.filters.value2 = res.dictValue
    },
    resetForm () {
      this.$refs.formModel.resetFields()
      this.filters.value2 = ''
      this.filters.value1 = ''
      this.ruleForm.dictCode = ''
      this.ruleForm.dictValue = ''
      // this.$refs["addService"].clearform();
    },
    // 删除
    async deleteDic () {
      this.multipleSelection.forEach(item => {
        this.dictIds.push(item.dictId)
      })
      if (this.dictIds && this.dictIds.length === 0) {
        // this.$message.error('请至少选择一条删除项')
        this.$confirm('请选择要删除的字典项', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'error'
        }).then(() => {
        }).catch(() => {
        })
        return
      }
      this.$confirm('此操作将删除字典项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.deleteDict({ dictIds: this.dictIds }).then(res => {
          if (!res) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.queryDictionItemList()
            this.dictIds = []
          } else {
            this.queryDictionItemList()
            this.dictIds = []
            // this.$CF.errorTips.call(this, res.data.code, res.data.message, null)
          }
        })
      })
    },
    async deleteOne (val) {
      this.$confirm('此操作将删除字典项, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$api.deleteDict({ dictIds: [val] }).then(res => {
          if (!res) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.queryDictionItemList()
            this.dictIds = []
          } else {
            // this.$CF.errorTips.call(this, res.data.code, res.data.message, null)
          }
        })
      })
    },
    change (e) {
      this.$forceUpdate()
    },
    handleChange (val) {
      this.queryDictionList(val)
    },

    handleCurrentChange (val) {
      this.queryDictionItemList(val)
    },
    showItem (dictType, dictTypeName) {
      this.dictionItemList(dictType)
      window.sessionStorage.setItem('dictType', dictType)
      window.sessionStorage.setItem('dictTypeName', dictTypeName)
    },
    async dictionItemList (dictType) {
      const res = await this.$api.getRightDictList(
        Object.assign(
          {
            dictType: dictType || ''
          },
          { pageNo: 1, pageSize: 10 }
        )
      )
      // 出错不处理
      if (res === false) {
        return
      }
      const { data = [], pageNo, pageSize, total } = res
      this.detailData.data = data
      this.detailData.pageNo = pageNo
      this.detailData.pageSize = pageSize
      this.detailData.total = total
      this.flag = true
    },
    // 弹窗左边的字典列表
    async selectDictionList (pageNumber) {
      const res = await this.$api.getLeftDictList(
        Object.assign(
          {
            pageNo: pageNumber || 0
          },
          { pageSize: 200 }
        )
      )
      // 出错不处理
      if (res === false) {
        return
      }
      const { data = [] } = res
      this.dictionaryData.data01 = data
    },
    // 右边的字典项
    async selectList () {
      this.select2 = []
      this.filters.value2 = ''
      const obj = {}
      obj.dictType = this.filters.value1
      obj.pageSize = 200 // 右边字典项要默认选所有，默认传200可满足要求
      //   obj.dictTypeName=window.sessionStorage.getItem(dictTypeName)
      const res = await this.$api.getRightDictList(Object.assign({}, obj))
      // 出错不处理
      if (res === false) {
        return
      }
      this.loading = false
      const { data = [] } = res
      this.select2 = data
    },
    selectTwoList (val) {
      if (val) {
        window.sessionStorage.setItem('_parentId', val)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.dictionary {
  padding-bottom: 30px;
  .el-dropdown {
    vertical-align: top;
  }

  .el-dropdown + .el-dropdown {
    margin-left: 15px;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .tabs-area {
    padding: 0 1.375rem;

    .el-tabs {
      background: #fff;

      .mainContent {
        padding-top: 0;
        .add-control {
          text-align: left;
          padding-right: 1.5rem;
          margin-bottom: 1rem;
        }
        .dataArea {
          .add-control {
            text-align: left;
            padding-right: 1.5rem;
          }

          .paginationArea {
            padding-top: 1rem;
          }
        }
      }
    }
  }
}
</style>
